<script setup>
    /**
     * Link in absolute position and inset 0
     * to cover the whole parent element
     */

    // Define props
    const props = defineProps({
        // The path to redirect to
        to: {
            type: String,
            required: true,
        },
    });
</script>

<template>
    <NuxtLink :to="props.to" class="redirect"/>
</template>

<style lang="scss" scoped>
    .redirect {
        position: absolute;
        inset: 0;
        z-index: 2;
    }
</style>
